import React from 'react';
import { Routes, Route } from "react-router-dom";
import Accueil from "./pages/Accueil";
import Email from "./pages/Email";
import Page404 from "./pages/Page404";
import HeaderMenu from "./components/HeaderMenu";
import FooterMenu from "./components/FooterMenu";
import './App.css';
import './styles/bootstrap-grid.min.css';
import './styles/theme.css';
import './styles/notification.css';

function App() {
  return (
    <div>
      <HeaderMenu />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/email/:id" element={<Email />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <FooterMenu />
    </div>
  );
}

export default App;
