import HeaderPresentation from "../components/HeaderPresentation";
import EmailTemplateCard from "../components/EmailTemplateCard";
import FormContact from "../components/FormContact";

function Accueil() {
    return (
        <div>
            <HeaderPresentation 
                titre="Transformez vos e-mails en chiffre d'affaires" 
                description="Obtenez de nouveaux clients avec la marque leader en automatisations et en marketing par e-mail* qui vous explique comment générer plus d'ouvertures, de clics et de ventes." 
                nomBouton="Nous contacter" 
                lienBouton="/#contact"
            />

            <div className="container">
                <div className="row mt-5">
                    <div className="col-md text_center">
                        <h2>Modèles de template mail</h2>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md text_center">
                        <EmailTemplateCard srcImage="/images/email-template-1.jpg" lien="/email/1" />
                    </div>
                    <div className="col-md text_center">
                        <EmailTemplateCard srcImage="/images/email-template-1.jpg" lien="/email/2" />
                    </div>
                    <div className="col-md text_center">
                        <EmailTemplateCard srcImage="/images/email-template-1.jpg" lien="/email/3" />
                    </div>
                </div>
                <div id="contact" className="row mt-5">
                    <div className="col-md text_center">
                        <h2>Contact</h2>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md">
                        <FormContact />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Accueil;