function FormContact() {
    return (
        <form className="row small_container">
            <div className="col-md-6 mb-4">
                <label htmlFor="prenom" className="label">Prénom</label>
                <input id="prenom" name="date_envoie" className="input full_width" type="text" placeholder="Emma" />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="nom" className="label">Nom</label>
                <input id="nom" name="nom" className="input full_width" type="text" placeholder="WATSON" />
            </div>
            <div className="col-md-12 mb-4">
                <label htmlFor="email" className="label">Email</label>
                <input id="email" name="email" className="input full_width" type="email" placeholder="contact@gmail.com" />
            </div>
            <div className="col-md-12 mb-4">
                <label htmlFor="sujet" className="label">Sujet</label>
                <input id="sujet" name="sujet" className="input full_width" type="text" placeholder="Fonctionnement" />
            </div>
            <div className="col-md-12 mb-4">
                <label htmlFor="message" className="label">Message</label>
                <textarea id="message" name="message" className="input full_width" tabIndex="5" rows="9" placeholder="Description"></textarea>
            </div>
            <div className="col-md-12 mb-4 text_center">
                <input className="bouton_plein_color" type="submit" value="Envoyer" />
            </div>
        </form>
    );
}

export default FormContact;