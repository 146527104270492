import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import HeaderPresentation from "../components/HeaderPresentation";
import EmailTemplate from "../components/EmailTemplate";

function Email() {
    useEffect(() => {
        let contenteditable = document.querySelectorAll("[contenteditable]");
        let input = document.querySelectorAll("input[type=texte], input[type=email]");
        let input_submit = document.getElementById("envoyer_contacts");
        let input_file = document.getElementById("liste_destinataires");

        axios.get('https://davymail-api.one-website.com/emails/get/' + localStorage.getItem('id_email'), {})
        .then(function (response) {
            if (response.data && response.data) {
                if (response.data.email_expediteur) {document.getElementById("email_expediteur").value = response.data.email_expediteur;}
                if (response.data.sujet) {document.getElementById("sujet").value = response.data.sujet;}
                if (response.data.lien) {document.getElementById("lien").value = response.data.lien;}
                if (response.data.titre) {document.getElementById("titre").innerHTML = response.data.titre;}
                if (response.data.messages) {document.getElementById("messages").innerHTML = response.data.messages;}
                if (response.data.bouton) {document.getElementById("bouton").innerHTML = response.data.bouton;}
            }
        });

        function envoyerMail() {
            if (localStorage.getItem('id_email')) {
                axios.post('https://davymail-api.one-website.com/emails/send/' + localStorage.getItem('id_email'), {})
                .then(function (response) {
                    if (response.data && response.data.message) {
                        document.getElementById("notification").innerHTML = '<p class="notif_success">' + response.data.message + '</p>';
                    }
                });
            }
        }

        function updateData(data) {
            if (!localStorage.getItem('id_email')) {
                data["titre"] = document.getElementById("titre").innerText;
                data["messages"] = document.getElementById("messages").innerText;
                data["bouton"] = document.getElementById("bouton").innerText;
                axios.post('https://davymail-api.one-website.com/emails/create', data)
                .then(function (response) {
                    if (response.data && response.data._id) {
                        localStorage.setItem('id_email', response.data._id);
                    }
                });
            }
            else {
                axios.put('https://davymail-api.one-website.com/emails/update/' + localStorage.getItem('id_email'), data);
            }
        }

        function updateDataFile(data) {
            if (!localStorage.getItem('id_email')) {
                axios.post('https://davymail-api.one-website.com/emails/createfile', data, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(function (response) {
                    if (response.data && response.data._id) {
                        localStorage.setItem('id_email', response.data._id);
                    }
                });
            }
            else {
                axios.put('https://davymail-api.one-website.com/emails/updatefile/' + localStorage.getItem('id_email'), data, {headers: {'Content-Type': 'multipart/form-data'}});
            }
        }

        function contenteditableUpdate(element) {
            let data = {};
            
            data[element.getAttribute("data-name")] = element.innerText;
            updateData(data);
        }

        function inputUpdate(element) {
            let data = {};

            data[element.getAttribute("data-name")] = element.value;
            data['email_template_id'] = document.getElementById("email_template_id").value;
            updateData(data);
        }

        function inputFileUpdate() {
            let form_data = new FormData();

            form_data.append("liste_destinataires", document.getElementById("liste_destinataires").files[0]);
            updateDataFile(form_data);
        }
        
        if (contenteditable) {
            let contenteditable_length = contenteditable.length;
        
            for (let i = 0; i < contenteditable_length; i++) {
                contenteditable[i].addEventListener("focusout", () => {
                    contenteditableUpdate(contenteditable[i]);
                });
            }
        }
        
        if (input) {
            let input_length = input.length;
        
            for (let i = 0; i < input_length; i++) {
                input[i].addEventListener("focusout", () => {
                    inputUpdate(input[i]);
                });
            }
        }
        
        if (input_submit) {
            input_submit.addEventListener("click", () => {
                envoyerMail();
            });
        }
        
        if (input_file) {
            input_file.addEventListener("change", () => {
                inputFileUpdate();
            });
        }
    }, []);

    return (
        <div>
            <HeaderPresentation
                titre="Personnalisé votre email"
                description="Vendez davantage encore grâce aux personnalisations, aux outils d'optimisation et aux automatisations enrichies."
                nomBouton="Nous contacter"
                lienBouton="/#contact"
            />

            <div id="notification" className="container my-5">
            </div>

            <div className="container my-5">
                <div className="row">
                    <div className="col-md-8 mb-5">
                        <EmailTemplate id={useParams().id} />
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="mb-4">
                            <label htmlFor="lien" className="label">Lien du bouton</label>
                            <input id="lien" name="lien" data-name="lien" className="input full_width" type="texte" placeholder="https://site.com/produit" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="sujet" className="label">Sujet (objet mail)</label>
                            <input id="sujet" name="sujet" data-name="sujet" className="input full_width" type="texte" placeholder="[DavyMail] - Nouveaux produits" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email_expediteur" className="label">Email expéditeur</label>
                            <input id="email_expediteur" name="email_expediteur" data-name="email_expediteur" className="input full_width" type="email" placeholder="conctat@gmail.com" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="liste_destinataires" className="label">Liste des destinataires (.csv)</label>
                            <input id="liste_destinataires" name="liste_destinataires" data-name="liste_destinataires" className="full_width" type="file" />
                        </div>
                        <div className="mb-4">
                            <a href="/fichiers/clients.csv" className="bouton_trait_color">Télécharger model de .csv</a>
                        </div>
                        <div>
                            <input id="email_template_id" name="email_template_id" data-name="email_template_id" className="full_width" type="hidden" value={useParams().id} />
                        </div>
                        <div className="mb-4">
                            <input id="envoyer_contacts" className="bouton_plein_color" type="submit" value="Envoyer aux contacts" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Email;
