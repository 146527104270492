import { Link } from "react-router-dom";
import phoneDavymail from '../images/phone-davymail.png';

function HeaderPresentation({ titre, description, nomBouton="En savoir plus", lienBouton="/" }) {
    return (
        <div className="bg_image_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 py-5 flex_center_y">
                        <div>
                            <h1 className="color_white">{ titre }</h1>
                            <p className="color_white paragraphe py-3">{ description }</p>
                            <Link to={ lienBouton } className="bouton_trait_blanc_anim hover_slide_down"><span>{ nomBouton }</span></Link>
                        </div>
                    </div>
                    <div className="col-md-5 py-4 text_right">
                        <img src={ phoneDavymail } alt="phone DavyMail" height="340px" className="my-3"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderPresentation;