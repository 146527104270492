import { Link } from "react-router-dom";
import logo from '../images/logo-davymail.png';

function HeaderMenu() {
    return (
        <header>
            <div className="bg_color_3">
                <div className="container">
                    <p className="py-3 color_white text_right">Automatiser vos emails facilement grâce à DavyMail</p>
                </div>
            </div>
            <div className="container flex_center_y menu_height">
                <Link to="/"><img src={ logo } alt="logo DavyMail" height="50px" className="my-3"/></Link>
                <p className="text_right full_width display_pc">
                    <Link to="/" className="link_hover py-3 ps-3">Accueil</Link>
                    <Link to="/#contact" className="link_hover py-3 ps-3">Contact</Link>
                </p>
                <p className="text_right full_width display_mobile">
                    <Link to="/#contact" className="link_hover py-3 ps-3">Contact</Link>
                </p>
            </div>
        </header>
    );
}

export default HeaderMenu;