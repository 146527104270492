import HeaderPresentation from "../components/HeaderPresentation";

function Page404() {
    return (
        <div>
            <HeaderPresentation 
                titre="Page non trouvé" 
                description="Retourner à l'accueil" 
                nomBouton="Accueil" 
                lienBouton="/"
            />
            
            <div className="container my-5">
                <div className="row py-5 my-5">
                    <div className="col-md py-5 my-5 text_center">
                        <h1>Erreur 404</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;
