import React from 'react';
import fondNoel from '../images/fond-noel.jpg';

function EmailTemplate({ id }) {
    if (id === "1") {
        return (
            <div Style="color: #454545; background-color: #fff8fa; padding: 32px 0;">
                <div Style="max-width: 520px; width: 90%; margin: 32px auto; border-radius: 12px; background-color: #ffffff;">
                    <img src={fondNoel} alt="fond" width="100%" Style="border-radius: 12px;" />
                    <div Style="padding: 32px;">
                        <h1 contentEditable={true} suppressContentEditableWarning={true} data-name="titre" id="titre" Style="margin: 0; padding-bottom: 8px;">Nouveaux produits</h1>
                        <p contentEditable={true} suppressContentEditableWarning={true} data-name="messages" id="messages" Style="margin: 16px 0; padding-bottom: 16px;">Quibus ita sceleste patratis Paulus cruore perfusus reversusque ad principis castra multos coopertos paene catenis adduxit in squalorem deiectos atque maestitiam, quorum adventu intendebantur eculei uncosque parabat carnifex et tormenta. et ex is proscripti sunt plures actique in exilium alii, non nullos gladii consumpsere poenales. nec enim quisquam facile meminit sub Constantio, ubi susurro tenus haec movebantur, quemquam absolutum.</p>
                        <a contentEditable={true} suppressContentEditableWarning={true} data-name="bouton" id="bouton" href="#lien" Style="margin: 0; padding: 8px 16px; font-weight: 500; font-size: 15px; cursor: pointer; color: white; background-color: #d31d53; border: 1px solid #d31d53; border-radius: 8px; text-decoration: none;">En savoir plus</a>
                    </div>
                </div>
            </div>
        );
    }
    if (id === "2") {
        return (
            <div Style="color: #454545; background-color: #fff8fa; padding: 32px 0;">
                <div Style="max-width: 520px; width: 90%; margin: 32px auto; border-radius: 12px; background-color: #ffffff;">
                    <img src={fondNoel} alt="fond" width="100%" Style="border-radius: 12px;" />
                    <div Style="padding: 32px;">
                        <h1 contentEditable={true} suppressContentEditableWarning={true} data-name="titre" id="titre" Style="margin: 0; padding-bottom: 8px;">Nouveaux produits</h1>
                        <p contentEditable={true} suppressContentEditableWarning={true} data-name="messages" id="messages" Style="margin: 16px 0; padding-bottom: 16px;">Quibus ita sceleste patratis Paulus cruore perfusus reversusque ad principis castra multos coopertos paene catenis adduxit in squalorem deiectos atque maestitiam, quorum adventu intendebantur eculei uncosque parabat carnifex et tormenta. et ex is proscripti sunt plures actique in exilium alii, non nullos gladii consumpsere poenales. nec enim quisquam facile meminit sub Constantio, ubi susurro tenus haec movebantur, quemquam absolutum.</p>
                        <a contentEditable={true} suppressContentEditableWarning={true} data-name="bouton" id="bouton" href="#lien" Style="margin: 0; padding: 8px 16px; font-weight: 500; font-size: 15px; cursor: pointer; color: white; background-color: #d31d53; border: 1px solid #d31d53; border-radius: 8px; text-decoration: none;">En savoir plus</a>
                    </div>
                </div>
            </div>
        );
    }
    if (id === "3") {
        return (
            <div Style="color: #454545; background-color: #fff8fa; padding: 32px 0;">
                <div Style="max-width: 520px; width: 90%; margin: 32px auto; border-radius: 12px; background-color: #ffffff;">
                    <img src={fondNoel} alt="fond" width="100%" Style="border-radius: 12px;" />
                    <div Style="padding: 32px;">
                        <h1 contentEditable={true} suppressContentEditableWarning={true} data-name="titre" id="titre" Style="margin: 0; padding-bottom: 8px;">Nouveaux produits</h1>
                        <p contentEditable={true} suppressContentEditableWarning={true} data-name="messages" id="messages" Style="margin: 16px 0; padding-bottom: 16px;">Quibus ita sceleste patratis Paulus cruore perfusus reversusque ad principis castra multos coopertos paene catenis adduxit in squalorem deiectos atque maestitiam, quorum adventu intendebantur eculei uncosque parabat carnifex et tormenta. et ex is proscripti sunt plures actique in exilium alii, non nullos gladii consumpsere poenales. nec enim quisquam facile meminit sub Constantio, ubi susurro tenus haec movebantur, quemquam absolutum.</p>
                        <a contentEditable={true} suppressContentEditableWarning={true} data-name="bouton" id="bouton" href="#lien" Style="margin: 0; padding: 8px 16px; font-weight: 500; font-size: 15px; cursor: pointer; color: white; background-color: #d31d53; border: 1px solid #d31d53; border-radius: 8px; text-decoration: none;">En savoir plus</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailTemplate;