import logoBlanc from '../images/logo-davymail-blanc.png';

function FooterMenu() {
    return (
        <footer className="bg_color_3 color_white text_center">
            <img src={ logoBlanc } alt="logo DavyMail" height="70px" className="my-5"/>
        </footer>
    );
}

export default FooterMenu;
