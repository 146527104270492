import { Link } from "react-router-dom";

function EmailTemplateCard({ srcImage, altImage="Email template", lien="/" }) {
    return (
        <Link to={ lien }>
            <div className="box_shadow">
                <img src={ srcImage } alt={ altImage } width="100%"/>
            </div>
        </Link>
    );
}

export default EmailTemplateCard;